export const getAllDecisions =
    /* GraphQL */
    `
        query getAllDecisions {
            getAllDecisions {
                id
                name
                workspace_id
            }
        }
    `;

export const getDecision =
    /* GraphQL */
    `
        query getDecision($decision_id: Int!) {
            getDecision(decision_id: $decision_id) {
                id
                name
                workspace_id
                json
            }
        }
    `;

export const getDecisions =
    /* GraphQL */
    `
        query getDecisions($workspace_id: Int!) {
            getDecisions(workspace_id: $workspace_id) {
                id
                name
                workspace_id
                json
                description
                owner
                created
                updated
            }
        }
    `;

export const getDecisionDefaults =
    /* GraphQL */
    `
        query getDecisionDefaults($decision_id: Int!) {
            getDecisionDefaults(decision_id: $decision_id) {
                value_type
                index_rule_id
                score_rule_id
                set_id
            }
        }
    `;

export const getAllWorkspaces =
    /* GraphQL */
    `
        query getAllWorkspaces {
            getAllWorkspaces {
                id
                name
                json
                description
                owner
                created
                updated
            }
        }
    `;

export const getFactor =
    /* GraphQL */
    `
        query getFactor($factor_id: Int!) {
            getFactor(factor_id: $factor_id) {
                id
                decision_id
                parent_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                format
                suffix
                value_type
                unit_id
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
            }
        }
    `;

export const getFactors =
    /* GraphQL */
    `
        query getFactors($decision_id: Int!) {
            getFactors(decision_id: $decision_id) {
                id
                decision_id
                parent_id
                # ... reference_id
                owner
                name
                description
                json
                order_str
                is_group
                is_table
                is_column
                # ... is_row
                # ... is_bp
                format
                suffix
                value_type
                unit_id
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    `;

export const getRootFactors =
    /* GraphQL */
    `
        query getFactors($decision_id: Int!, $root_only: Boolean!) {
            getFactors(decision_id: $decision_id, root_only: $root_only) {
                id
                decision_id
                parent_id
                description
                # ... reference_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                # ... is_row
                # ... is_bp
                format
                suffix
                value_type
                unit_id
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    `;

export const getChildFactors =
    /* GraphQL */
    `
        query getFactors($decision_id: Int!, $parent_id: Int!) {
            getFactors(decision_id: $decision_id, parent_id: $parent_id) {
                id
                decision_id
                parent_id
                description
                # ... reference_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                # ...is_row
                # ... is_bp
                format
                suffix
                value_type
                unit_id
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                min
                max
            }
        }
    `;

export const listChoices = /* GraphQL */ `
    query ListChoices(
        $filter: ModelChoiceFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listChoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                version_id
                name
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const getChoices = `query getChoices($decision_id: Int!){
    getChoices(decision_id: $decision_id) {
        id
        name
        decision_id
        owner
        description
        created
        updated
    }
}
`;

export const getValuesByC = `query getValuesByC($choice_id: Int!,$root_only: Boolean, $parent_id: Int) { 
    getValuesByC(choice_id: $choice_id, root_only: $root_only, parent_id: $parent_id) {
        choice_id
        factor_id
        row_id
        json
        value
        enum_value_id
        comments
    }
}
`;

export const getValuesByD = `query getValuesByD($decision_id: Int!,$root_only: Boolean, $parent_id: Int) { 
    getValuesByD(decision_id: $decision_id, root_only: $root_only, parent_id: $parent_id) {
        choice_id
        factor_id
        row_id
        json
        value
        enum_value_id
    }
}
`;

export const getFactorValues = `query getFactorValues($factor_id: Int!) { 
    getFactorValues(factor_id: $factor_id) {
        choice_id
        factor_id
        row_id
        json
        value
        enum_value_id
    }
}
`;

export const getViewpoints = `query getViewpoints($decision_id: Int!) {
    getViewpoints(decision_id: $decision_id) {
        id
        name
        description
        owner
        created
        updated
    }
}
`;

export const getScores = `query getScores($viewpoint_id: Int, $choice_id: Int, $factor_id: Int) {
    getScores(viewpoint_id: $viewpoint_id, choice_id: $choice_id, factor_id: $factor_id) {
        choice_id
        factor_id
        viewpoint_id
        row_id
        m_score
        c_score
        class {
            id
            workspace_id
            name
            json
        }
        m_index
        c_index
    }
}
`;

export const getScoresD = `query getScoresD(
    $d_id: Int!,
    $root: Boolean,
    $fp_ids: [Int!],
    $v_ids: [Int!],
    $c_ids: [Int!],
    $v_slice: Slice,
    $c_slice: Slice,
    $f_slice: Slice

) {
    getScoresD(
        d_id: $d_id
        root: $root
        fp_ids: $fp_ids
        v_ids: $v_ids
        c_ids: $c_ids
        v_slice: $v_slice
        c_slice: $c_slice
        f_slice: $f_slice
    ) {
        vs {
            id
            name
        }
        cs {
            id
            name
        }
        fs {
            id
            name
        }
        ss {
            v_id
            c_id
            f_id
            row_id
            m_score
            c_score
            unit_id
            use_m_score
            use_m_index
            m_index
            c_index
            min
            max
            order_str 
            class_id
        }
    }
}
`;

export const getScoreMaps =
    /* GraphQL */
    `
        query getScoreMaps {
            getScoreMaps {
                id
                decision_id
                name
                map_ranges {
                    at_least
                    rep
                    score
                }
            }
        }
    `;

export const getScoreMap =
    /* GraphQL */
    `
        query getScoreMap($score_map_id: Int!) {
            getScoreMap(id: $score_map_id) {
                name
                map_ranges {
                    at_least
                    rep
                    score
                }
            }
        }
    `;

export const getRules =
    /* GraphQL */
    `
        query getRules($workspace_id: Int!) {
            getRules(workspace_id: $workspace_id) {
                id
                name
                decision_id
                null_value_score
                interpolate_range
                is_range
                is_match
                is_index_rule
                index_uses_score
                no_match_score
                min_range_score
                is_aggregate
                aggregate_function
                min
                max
                threshold
                org_id
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
        }
    `;

export const getRule =
    /* GraphQL */
    `
        query getRule($rule_id: Int!) {
            getRule(rule_id: $rule_id) {
                id
                name
                decision_id
                null_value_score
                interpolate_range
                is_range
                is_match
                is_index_rule
                index_uses_score
                no_match_score
                min_range_score
                is_aggregate
                aggregate_function
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
        }
    `;

export const getClassRules =
    /* GraphQL */
    `
        query getClassRules {
            getClassRules {
                id
            }
        }
    `;

export const getClassSet =
    /* GraphQL */
    `
        query getClassSet($set_id: Int!) {
            getClassSet(set_id: $set_id) {
                id
                org_id
                workspace_id
                name
                mappings {
                    set_id
                    class_id
                    fail
                    index
                    json
                    score
                    displayname
                }
                json
            }
        }
    `;

export const getClassSets =
    /* GraphQL */
    `
        query getClassSets($workspace_id: Int!) {
            getClassSets(workspace_id: $workspace_id) {
                id
                org_id
                workspace_id
                name
                mappings {
                    set_id
                    class_id
                    fail
                    index
                    json
                    score
                }
                json
            }
        }
    `;

// export const getFactorTypes =
//     /* GraphQL */
//     `
//         query getFactorTypes {
//             getFactorTypes {
//                 id
//                 decision_id
//                 factor_id
//                 name
//                 json
//                 default_cluster_id
//                 default_score_rule_id
//             }
//         }
//     `;

export const getClasses =
    /* GraphQL */
    `
        query getClasses($workspace_id: Int!) {
            getClasses(workspace_id: $workspace_id) {
                id
                workspace_id
                name
                description
                json
            }
        }
    `;

export const getViewpointMappings =
    /* GraphQL */
    `
        query getViewpointMappings(
            $viewpoint_id: Int!
            $root_only: Boolean
            $parent_id: Int
        ) {
            getViewpointMappings(
                viewpoint_id: $viewpoint_id
                root_only: $root_only
                parent_id: $parent_id
            ) {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                unit_id
                set_id
                json
                order_str
                weight
                use_m_score
                use_m_index
            }
        }
    `;

export const getViewpointMappingsByParent =
    /* GraphQL */
    `
        query getViewpointMappingsByParent(
            $viewpoint_id: Int!
            $root_only: Boolean
            $parent_id: Int
        ) {
            getViewpointMappingsByParent(
                viewpoint_id: $viewpoint_id
                root_only: $root_only
                parent_id: $parent_id
            ) {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                unit_id
                set_id
                json
                order_str
                weight
                use_m_score
                use_m_index
            }
        }
    `;

export const getAllUnits =
    /* GraphQL */
    `
        query getAllUnits {
            getAllUnits {
                id
                name
                abrv
            }
        }
    `;

export const getUnits =
    /* GraphQL */
    `
        query getUnits($domain_id: Int!) {
            getUnits(domain_id: $domain_id) {
                id
                name
                abrv
            }
        }
    `;

export const getAllUnitConvs =
    /* GraphQL */
    `
        query getAllUnitConvs {
            getAllUnitConvs {
                from_id
                to_id
                multiplier
            }
        }
    `;

export const getEnums =
    /* GraphQL */
    `
        query getEnums($decision_id: Int!) {
            getEnums(decision_id: $decision_id) {
                id
                decision_id
                name
                values {
                    id
                    enum_id
                    value
                }
            }
        }
    `;

export const getEnum =
    /* GraphQL */
    `
        query getEnum($enum_id: Int!) {
            getEnum(enum_id: $enum_id) {
                id
                decision_id
                name
                values {
                    id
                    enum_id
                    value
                }
            }
        }
    `;

export const getApp =
    /* GraphQL */
    `
        query getApp($app_id: Int!) {
            getApp(app_id: $app_id) {
                id
                decision_id
                title
                description
                header
                json
                image
                owner
                created
                updated
                tabs {
                    id
                    decision_id
                    title
                    json
                    edit_flags
                    display_flags
                    filter_type
                    row_type
                    column_type
                    root_factor_id
                    filter_factor_id
                    filter_choice_id
                    filter_viewpoint_id
                    type
                    description
                    order_str
                }
            }
        }
    `;

export const getApps =
    /* GraphQL */
    `
        query getApps($decision_id: Int!) {
            getApps(decision_id: $decision_id) {
                id
                decision_id
                title
                description
                header
                json
                image
                owner
                created
                updated
                tabs {
                    id
                    decision_id
                    title
                    json
                    edit_flags
                    display_flags
                    filter_type
                    row_type
                    column_type
                    root_factor_id
                    filter_factor_id
                    filter_choice_id
                    filter_viewpoint_id
                    type
                    description
                    order_str
                }
            }
        }
    `;

export const getTabFactors =
    /* GraphQL */
    `
        query getTabFactors($tab_id: Int!) {
            getTabFactors(tab_id: $tab_id) {
                tab_id
                factor_id
            }
        }
    `;

export const getTabChoices =
    /* GraphQL */
    `
        query getTabChoices($tab_id: Int!) {
            getTabChoices(tab_id: $tab_id) {
                tab_id
                choice_id
            }
        }
    `;

export const getTabViewpoints =
    /* GraphQL */
    `
        query getTabViewpoints($tab_id: Int!) {
            getTabViewpoints(tab_id: $tab_id) {
                tab_id
                viewpoint_id
            }
        }
    `;

export const getPdfResult =
    /* GraphQL */
    `
        query getPdfResult($task_id: String!) {
            getPdfResult(task_id: $task_id) {
                task {
                    id
                    status
                    timestamp
                }
                result {
                    id
                    name
                    parent_id
                    group_id
                }
            }
        }
    `;

export const getTasks =
    /* GraphQL */
    `
        query getTasks($limit: Int!, $status: String!) {
            getTasks(limit: $limit, status: $status) {
                id
                status
                timestamp
            }
        }
    `;

export const getUser =
    /* GraphQL */
    `
        query getUser($email: String!) {
            getUser(email: $email) {
                id
                first
                last
                email
            }
        }
    `;

export const getAllUsers =
    /* GraphQL */
    `
        query getAllUsers {
            getAllUsers {
                id
                first
                last
                email
            }
        }
    `;

export const getWorkspaceUsers =
    /* GraphQL */
    `
        query getWorkspaceUsersL($workspace_id: Int!) {
            getWorkspaceUsersL(workspace_id: $workspace_id) {
                user {
                    id
                    first
                    last
                    email
                }
                permission {
                    workspace_id
                    user_id
                    read
                    write
                    admin
                    owner_only
                    fc_r
                    fc_w
                    vp_r
                    vp_w
                    vp_c
                    fi_r
                    fi_w
                    ch_r
                    ch_w
                    ch_c
                    va_r
                    va_w
                    sc_r
                    sc_w
                }
            }
        }
    `;

export const getMyPermissions =
    /* GraphQL */
    `
        query getMyPermissions {
            getMyPermissions {
                workspace_id
                user_id
                read
                write
                admin
                owner_only
                fc_r
                fc_w
                vp_r
                vp_w
                vp_c
                fi_r
                fi_w
                ch_r
                ch_w
                ch_c
                va_r
                va_w
                sc_r
                sc_w
            }
        }
    `;

export const getUserDecisionCount =
    /* GraphQL */
    `
        query getUserDecisionCount {
            getUserDecisionCount
        }
    `;
export const getGeneratedFactors =
    /* GraphQL */
    `
        query getGeneratedFactors(
            $topic_area: String!
            $creativity: Float!
            $stage: String!
            $persona: String!
        ) {
            getGeneratedFactors(
                topic_area: $topic_area
                creativity: $creativity
                stage: $stage
                persona: $persona
            ) {
                id
                status
                timestamp
            }
        }
    `;

export const getGeneratedFactorsResults =
    /* GraphQL */
    `
        query getGeneratedFactorsResults($task_id: String!, $stage: String!) {
            getGeneratedFactorsResults(task_id: $task_id, stage: $stage) {
                task {
                    id
                    status
                }
                result {
                    factor_id
                    task_id
                    name
                    description
                    type
                    parent_id
                }
            }
        }
    `;

export const getGeneratedCategoryOptions =
    /* GraphQL */
    `
        query getGeneratedCategoryOptions(
            $topic_area: String!
            $creativity: Float!
            $stage: String!
            $categorical_factors: String!
        ) {
            getGeneratedCategoryOptions(
                topic_area: $topic_area
                creativity: $creativity
                stage: $stage
                categorical_factors: $categorical_factors
            ) {
                id
                status
                timestamp
            }
        }
    `;

export const getGeneratedCategoryOptionsResults =
    /* GraphQL */
    `
        query getGeneratedCategoryOptionsResults(
            $task_id: String!
            $stage: String!
        ) {
            getGeneratedCategoryOptionsResults(
                task_id: $task_id
                stage: $stage
            ) {
                task {
                    id
                    status
                }
                result {
                    cat_id
                    task_id
                    category_name
                    options
                }
            }
        }
    `;

export const getThreads =
    /* GraphQL */
    `
        query getThreads(
            $decision_id: Int!
            $include_resolved: Boolean!
            $limit: Int
            $offset: Int
        ) {
            getThreads(
                decision_id: $decision_id
                include_resolved: $include_resolved
                limit: $limit
                offset: $offset
            ) {
                id
                decision_id
                viewpoint_id
                choice_id
                factor_id
                row_id
                name
            }
        }
    `;

export const getComments =
    /* GraphQL */
    `
        query getComments($choice_id: Int!, $factor_id: Int!) {
            getComments(choice_id: $choice_id, factor_id: $factor_id) {
                id
                reply_to
                decision_id
                choice_id
                factor_id
                author_name
                made_by_you
                created
                updated
                comment
                num_replies
                mutation
            }
        }
    `;

export const getReplies =
    /* GraphQL */
    `
        query getReplies($comment_id: Int!) {
            getReplies(comment_id: $comment_id) {
                id
                reply_to
                decision_id
                choice_id
                factor_id
                author_name
                made_by_you
                created
                updated
                comment
                num_replies
                mutation
            }
        }
    `;

export const getOtherComments =
    /* GraphQL */
    `
        query getOtherComments(
            $thread_id: Int!
            $viewpoint_id: Int
            $choice_id: Int
            $factor_id: Int
        ) {
            getOtherComments(
                thread_id: $thread_id
                viewpoint_id: $viewpoint_id
                choice_id: $choice_id
                factor_id: $factor_id
            ) {
                id
                thread_id
                author_id
                created
                updated
                comment
                reply_to
                num_replies
            }
        }
    `;

export const getPendingInvites =
    /* GraphQL */
    `
        query getPendingInvites {
            getPendingInvites {
                email
                workspace_id
                read
                write
                admin
            }
        }
    `;
