
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Factor, Choice, UserPermission } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Choices from "@/store/modules/Choices";
import Factors from "@/store/modules/Factors";
import CommentUserRow from "@/components/comments/userTab/CommentUserRow.vue";

const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);

@Component({
    name: "CommentUserRecursive",
    components: {
        CommentUserRow,
    },
})
export default class CommentUserRecursive extends Vue {
    @Prop({ type: Number, default: -1 })
    factorRoot!: number;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({ default: () => [], type: Array })
    users!: UserPermission[];

    @Prop()
    choice!: Choice;

    @Prop({ default: () => [], type: Array })
    hiddenFactors!: number[];

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    private open: { [id: number]: number[] } = {};
    private valueLoading = false;

    get currentFactorTree(): Factor[] {
        if (modelModule.factorTree[this.factorRoot]) {
            return modelModule.factorTree[this.factorRoot].filter((factor) => {
                return !this.hiddenFactors.includes(factor.id);
            });
        } else {
            return [];
        }
    }

    get choiceId(): number | null {
        return this.choice ? this.choice.id : null;
    }

    mounted(): void {
        this.onFactorRootChange();
        this.loadValues();
        if (this.depth == 0) {
            Vue.set(this.open, 0, [0]);
        }
    }

    private async loadValues(): Promise<void> {
        if (this.choiceId) {
            this.valueLoading = true;
            await choiceModule.getChoiceValues({
                choice_id: this.choiceId,
                parent_id: this.factorRoot,
            });
            this.valueLoading = false;
        }
    }

    @Watch("choiceId")
    private async onChoiceIdChange(): Promise<void> {
        await this.loadValues();
    }

    @Watch("factorRoot")
    private async onFactorRootChange(): Promise<void> {
        if (this.factorRoot != -1 && !modelModule.factorTree[this.factorRoot]) {
            await modelModule.fetchChildFactors(this.factorRoot);
            await this.loadValues();
        }
    }
}
