import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import Workspaces from "@/store/modules/Workspaces";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import Decisions from "@/store/modules/Decisions";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import TabManagment from "@/components/mixins/TabManagment";
import CompareItems from "@/components/mixins/CompareItems";
import {
    Pagination,
    Tab,
    Locks,
    UiSettings,
    ScoreOptions,
    ScoreClassSettings,
    FactorOptions,
    Factor,
    Choice,
    Viewpoint,
} from "@/graphql/API";
import CompareObjects from "@/components/compare/CompareObjects.vue";
import FactorNav from "@/components/model/FactorNav.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";
import CompareSettings from "@/components/compare/CompareSettings.vue";
import CompareOptions from "@/components/compare/CompareOptions.vue";
import { saveTab } from "@/helpers/TabHelper";

const choiceModule = getModule(Choices);
const viewpointsModule = getModule(Viewpoints);
const modelModule = getModule(Factors);
const decisionsModule = getModule(Decisions);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        ViewWrapper,
        CompareObjects,
        FactorNav,
        ViewAppCreator,
        CompareSettings,
        CompareOptions,
    },
})
export default class CompareBase extends mixins(CompareItems) {
    @Prop({ default: "compare", type: String })
    type!: string;

    sidebarIndex = "sidebar-close";
    settingsMode = "tab-settings";
    mainColumnWidth = 400;
    dataColumnWidth = 300;
    showHeader = true;
    selectedFactorLoading = false;

    loadedDataItemId: number | null = null;
    factorSelectOpen = false;
    chosenFactor: number[] = [];

    unSavedWarningOpen = false;
    unSavedRoutingWarningOpen = false;
    saving = false;

    // Compare Tab Settings
    dataItem: Factor | Choice | Viewpoint | null = null;
    columns: "viewpoints" | "factors" | "choices" = "viewpoints";
    rows: "viewpoints" | "factors" | "choices" = "factors";
    data: "viewpoints" | "factors" | "choices" = "choices";

    pagination: Pagination = {
        columns: {
            all: false,
            items: 20,
            position: 0,
        },
    };

    indexIds: { [id: string]: "factor" | "choice" | "viewpoint" } = {
        factors: "factor",
        choices: "choice",
        viewpoints: "viewpoint",
    };
    private indexConversion: {
        [index: string]: "factors" | "choices" | "viewpoints";
    } = {
        factor: "factors",
        choice: "choices",
        viewpoint: "viewpoints",
    };

    indexes = {
        rows: "choices",
        columns: "viewpoints",
        data: "factors",
    };

    allFactors: Factor[] = [];
    rootFactorId = -1;

    appCreateOpen = false;

    get deactiveFactors(): number[] {
        return [...this.hiddenFactors, ...this.unselectedFactors];
    }

    get axisIndex(): {
        rows: "viewpoints" | "factors" | "choices";
        columns: "viewpoints" | "factors" | "choices";
        data: "viewpoints" | "factors" | "choices";
    } {
        return {
            rows: this.rows,
            columns: this.columns,
            data: this.data,
        };
    }

    /* Tab Settings */
    get currentUiOptions(): string {
        return JSON.stringify({
            mainColumn: this.mainColumnWidth,
            dataColumn: this.dataColumnWidth,
            paddingX: this.paddingX,
            paddingY: this.paddingY,
            borderX: this.borderX,
            borderY: this.borderY,
            pagination: this.pagination,
            rootFactor: this.rootFactorId,
            fullSingleCol: this.fullSingleCol,
        });
    }

    get currentEditFlags(): string {
        return JSON.stringify({
            choice: this.choiceEditable,
            score: this.scoresEditable,
            customScore: this.customScore,
            classRating: this.classRating,
            weights: this.weightsEditable,
            scoreRule: this.scoreRuleEditable,
            locks: this.locks,
            allChoices: this.allChoicesActive,
            allViewpoints: this.allViewpointsActive,
            allFactors: this.activeFactors.all,
            userChoices: this.userChoicesEnabled,
            userViewpoints: this.userViewpointsEnabled,
            selectUserChoices: this.selectUserChoices,
            selectUserViewpoints: this.selectUserViewpoints,
            hideChoices: this.hideChoices,
            hideViewpoints: this.hideViewpoints,
        });
    }

    get currentDisplayFlags(): string {
        return JSON.stringify({
            score: this.scoreDisplay,
            classSelector: this.classSelector,
            factor: this.factorDisplay,
        });
    }
    /* End of Tab Settings */

    /* URL query props */
    get workspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get factorsChanged(): boolean {
        if (this.tabFactorIds.length == this.deactiveFactors.length) {
            if (
                this.deactiveFactors.every((val: number) =>
                    this.tabFactorIds.includes(val)
                ) &&
                this.tabFactorIds.every((val: number) =>
                    this.deactiveFactors.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    /* Load Options for tab */
    private async loadTabOptions(): Promise<void> {
        if (this.tabSettings) {
            this.data = this.indexConversion[this.tabSettings.filter_type];
            this.rows = this.indexConversion[this.tabSettings.row_type];
            this.columns = this.indexConversion[this.tabSettings.column_type];

            await Promise.all([
                this.loadTabUiSettings(
                    this.tabSettings.json != ""
                        ? JSON.parse(this.tabSettings.json)
                        : null
                ),
                this.loadTabDisplayOptions(
                    this.tabSettings.display_flags != ""
                        ? JSON.parse(this.tabSettings.display_flags)
                        : null
                ),
                this.loadTabEditOptions(
                    this.tabSettings.edit_flags != ""
                        ? JSON.parse(this.tabSettings.edit_flags)
                        : null
                ),
            ]);

            this.loadedDataItemId = await this.loadTabFilterId(
                this.tabSettings ? this.tabSettings : null
            );
        }
    }

    private async loadTabFilterId(tab: Tab | null): Promise<number | null> {
        if (tab) {
            if (tab.filter_type == "factor") {
                return tab.filter_factor_id ? tab.filter_factor_id : null;
            } else if (tab.filter_type == "choice") {
                return tab.filter_choice_id ? tab.filter_choice_id : null;
            } else {
                return tab.filter_viewpoint_id ? tab.filter_viewpoint_id : null;
            }
        } else {
            return null;
        }
    }

    private async loadTabUiSettings(options: UiSettings) {
        if (options) {
            this.mainColumnWidth = options.mainColumn;
            this.dataColumnWidth = options.dataColumn;
            this.paddingX = options.paddingX;
            this.paddingY = options.paddingY;
            this.borderX = options.borderX;
            this.borderY = options.borderY;
            this.pagination = options.pagination;
            this.rootFactorId = options.rootFactor ? options.rootFactor : -1;
            this.fullSingleCol = options.fullSingleCol
                ? options.fullSingleCol
                : false;
        }
    }

    private async loadTabDisplayOptions(options: {
        score: ScoreOptions;
        classSelector: ScoreClassSettings;
        factor: FactorOptions;
    }) {
        if (options) {
            this.scoreDisplay = { ...options.score };
            this.classSelector = { ...options.classSelector };
            this.factorDisplay = { ...options.factor };
        }
    }

    private async loadTabEditOptions(options: {
        choice: boolean;
        score: boolean;
        customScore: boolean;
        classRating: boolean;
        weights: boolean;
        scoreRule: boolean;
        locks: Locks;
        allChoices: boolean;
        allViewpoints: boolean;
        allFactors: boolean;
        userChoices?: boolean;
        userViewpoints?: boolean;
        selectUserChoices?: boolean;
        selectUserViewpoints?: boolean;
        hideChoices?: boolean;
        hideViewpoints?: boolean;
    }) {
        if (options) {
            this.choiceEditable = options.choice;
            this.scoresEditable = options.score;
            this.customScore = options.customScore;
            this.classRating = options.classRating;
            this.weightsEditable = options.weights;
            this.scoreRuleEditable = options.scoreRule;
            this.locks = options.locks;
            this.activeChoices.all = options.allChoices;
            this.activeViewpoints.all = options.allViewpoints;

            this.showAllViewpointsTab = options.allViewpoints;
            this.showAllChoicesTab = options.allChoices;

            this.activeFactors.all = options.allFactors;
            this.userChoicesEnabled =
                options.userChoices != undefined ? options.userChoices : true;
            this.userViewpointsEnabled =
                options.userViewpoints != undefined
                    ? options.userViewpoints
                    : true;
            this.selectUserChoices =
                options.selectUserChoices != undefined
                    ? options.selectUserChoices
                    : true;
            this.selectUserViewpoints =
                options.selectUserViewpoints != undefined
                    ? options.selectUserViewpoints
                    : true;
            this.hideChoices = options.hideChoices != undefined ? options.hideChoices : false;
            this.hideViewpoints = options.hideViewpoints != undefined ? options.hideViewpoints : false;
        } else {
            this.choiceEditable = true;
            this.scoresEditable = true;
            this.customScore = true;
            this.classRating = true;
            this.weightsEditable = true;
            this.scoreRuleEditable = true;
            this.activeChoices.all = true;
            this.activeViewpoints.all = true;
            this.activeFactors.all = true;
            this.userChoicesEnabled = true;
            this.userViewpointsEnabled = true;
            this.selectUserChoices = true;
            this.selectUserViewpoints = true;
            this.hideChoices = false;
            this.hideViewpoints = false;
            if (!this.embed) {
                // this.onViewpointsChange(this.viewpoints);
                // this.onChoicesChange();
            }
        }
    }

    private async loadDataItem(
        id: number | undefined,
        index: string | undefined
    ): Promise<Choice | Factor | Viewpoint | null> {
        if (id && index) {
            if (index == "choices") {
                return choiceModule.choiceList[id];
            } else if (index == "viewpoints") {
                return viewpointsModule.viewpointList[id];
            } else {
                return await modelModule.fetchFactor(id);
            }
        } else {
            return null;
        }
    }
    /* End of load Options */

    /* Recursivly checks if selected id is a child of 
        the selected root group. Does not get called if 
        root is -1 or if selected factor is the selected root */
    isFactorInRoot(id: number | undefined): boolean {
        if (id && modelModule.factorMap[id]) {
            if (!modelModule.factorMap[id].parent_id) {
                return false;
            } else if (
                id == this.rootFactorId ||
                modelModule.factorMap[id].parent_id == this.rootFactorId
            ) {
                return true;
            } else {
                return this.isFactorInRoot(modelModule.factorMap[id].parent_id);
            }
        } else {
            return false;
        }
    }

    openSidebar(index: string): void {
        if (this.sidebarIndex != index) {
            this.sidebarIndex = index;
            this.settingsMode = index;
        } else {
            this.sidebarIndex = "sidebar-close";
        }
    }

    async mounted(): Promise<void> {
        this.loadingTabSettings = true;
        await this.loadTabOptions();
        this.loadingTabSettings = false;

        if (this.isMainTool) {
            this.selectUserChoices = true;
            this.selectUserViewpoints = true;
        }

        this.querySettings = false;

        if (this.decisionId) {
            await modelModule.getEnums(this.decisionId);
        }
    }

    @Watch("pageLoading")
    async onPageLoadingChange(): Promise<void> {
        if (!this.pageLoading) {
            if (this.loadedDataItemId && this.axisIndex.data) {
                this.dataItem = await this.loadDataItem(
                    this.loadedDataItemId,
                    this.page == "RankView" ? "Factors" : this.axisIndex.data
                );
                if (
                    (this.data == "factors" || this.page == "RankView") &&
                    this.dataItem
                ) {
                    this.chosenFactor = [this.dataItem.id];
                }
            }
        }
    }

    @Watch("rootFactorId")
    async onRootFactorIdChange(): Promise<void> {
        if (
            !modelModule.factorTree[this.rootFactorId] ||
            modelModule.factorTree[this.rootFactorId].length == 0
        ) {
            this.loadingTabSettings = true;
            await modelModule.fetchChildFactors(this.rootFactorId);
            this.loadingTabSettings = false;
        }
    }

    @Watch("tabPropId")
    async onTabSettingsChange(): Promise<void> {
        this.loadTabOptions();
    }
}
