
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import MediaFileItem from "@/components/choices/values/MediaFileItem.vue";
import FileUpload from "@/components/common/media/FileUpload.vue";
import { fileValue, FactorOptions } from "@/graphql/API";

@Component({
    components: {
        MediaFileItem,
        FileUpload,
        draggable,
    },
})
export default class MediaValueDisplay extends Vue {
    /* Props copied from FileUpload component */
    @Prop({ type: String, default: "mdi-image-plus" })
    iconSave!: string;

    @Prop({ type: String, default: "image" })
    fileType!: string;

    @Prop({ type: String, default: "mdi-image-edit" })
    iconEdit!: string;

    @Prop({ type: String, default: "mdi-trash-can-outline" })
    iconDelete!: string;

    @Prop({ type: String, default: "" })
    path!: string;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    @Prop({ type: Boolean, default: false })
    autosave!: boolean;

    @Prop({ type: String, default: "" })
    value!: string;

    @Prop({ type: Boolean, default: false })
    showPreviewModal!: boolean;

    @Prop({ type: Boolean, default: false })
    isFactorTable!: boolean;

    @Prop({ type: Boolean, default: false })
    scoringTab!: boolean;

    @Prop({ default: false, type: Boolean })
    isThumbnailDisplay!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: true, type: Boolean })
    condensed!: boolean;

    @Prop({ default: () => null, type: Object })
    valueJson!: any;

    @Prop({ type: Boolean, default: false })
    deleting!: boolean;

    @Prop({ type: String, default: "" })
    folderKey!: string;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private currIndex = 0;
    private fileList: fileValue[] = [];

    get valueList(): fileValue[] {
        if (this.value) {
            try {
                return JSON.parse(this.value);
            } catch {
                return [];
            }
        } else {
            return [];
        }
    }

    get draggableEnabled(): boolean {
        return this.fileType === "document" || this.fileType === "audio";
    }

    get sliderPresentation(): boolean {
        return (
            this.listLength > 1 && ["image", "video"].includes(this.fileType)
        );
    }

    get prevDisabled(): boolean {
        return this.currIndex === 0;
    }

    get nextDisabled(): boolean {
        return this.currIndex === this.listLength - 1;
    }

    get listLength(): number {
        return this.fileList.length;
    }

    mounted(): void {
        this.fileList = [...this.valueList];
    }

    private async deleteFile(path: string): Promise<void> {
        this.fileList = this.fileList.filter((item) => item.fileName !== path);
        this.$emit("files-change", this.fileList);
    }

    private async addFile(fileData: {
        fileName: string;
        srcFile: string;
    }): Promise<void> {
        this.fileList = [...this.fileList, fileData];
        this.$emit("files-change", this.fileList);
        this.currIndex = 0;
    }

    private onSort(): void {
        this.$emit("files-change", this.fileList);
    }

    @Watch("valueList", { deep: true })
    onValueListChange(): void {
        this.fileList = [...this.valueList];
    }

    @Watch("listLength")
    onListLengthChange(val: number): void {
        if (this.currIndex > val - 1) {
            this.currIndex = val - 1;
        }
    }
}
