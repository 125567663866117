
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { User, Workspace, App } from "@/graphql/API";
import { Urls } from "@/Urls";
import Workspaces from "@/store/modules/Workspaces";
import Apps from "@/store/modules/Apps";

const workspaceModule = getModule(Workspaces);
const appsModule = getModule(Apps);

@Component({
    components: {},
})
export default class WorkspaceInvite extends Vue {
    @Prop()
    user!: User;

    @Prop()
    workId!: number;

    @Prop()
    appId!: number | null;

    private readonly URLS = Urls;
    private inviteApp: App | null = null;
    private appLoading = false;

    get userLabel(): string {
        if (this.user) {
            if (this.user.first) {
                return this.user.last
                    ? `${this.user.first} ${this.user.last}`
                    : this.user.first;
            } else {
                return this.user.email ? this.user.email : "User";
            }
        } else {
            return "User not found";
        }
    }

    get workspacesLoading(): boolean {
        return workspaceModule.fetchingWorkspaces;
    }

    get invitedWorkspace(): Workspace | null {
        if (this.workId && workspaceModule.workspaces[this.workId]) {
            return workspaceModule.workspaces[this.workId];
        } else {
            return null;
        }
    }

    get app(): App | null {
        if (this.appId && appsModule.keyedApps[this.appId]) {
            return appsModule.keyedApps[this.appId];
        } else {
            return null;
        }
    }

    private async openWorkspace(): Promise<void> {
        if (this.workId) {
            if (this.inviteApp) {
                this.$router.push({
                    path: `${Urls.APPS}/${this.inviteApp.id}`,
                });
            } else {
                await workspaceModule.selectWorkspace(this.workId);
                this.$router.push({
                    path: Urls.HOME,
                });
            }
        }
    }

    mounted(): void {
        this.onAppIdChange(this.appId);
    }

    @Watch("appId")
    private async onAppIdChange(id: number | null) {
        if (id) {
            this.appLoading = true;
            const res = await appsModule.getApp(id);
            this.inviteApp = res;
            this.appLoading = false;
        }
    }
}
