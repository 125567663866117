
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Factor, Choice, Value } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Choices from "@/store/modules/Choices";
import Comments from "@/store/modules/Comments";

const choiceModule = getModule(Choices);
const commentModule = getModule(Comments);

@Component({
    components: {},
})
export default class CommentUserFactorChoice extends Vue {
    @Prop({ required: true })
    factor!: Factor;

    @Prop({ required: true })
    choice!: Choice;

    get choiceId(): string {
        return `${this.choice.id}`;
    }

    get factorId(): string {
        return `${this.factor.id}`;
    }

    get value(): Value | null {
        if (
            choiceModule.choiceValues[this.choice.id] &&
            choiceModule.choiceValues[this.choice.id][this.factorId]
        ) {
            return choiceModule.choiceValues[this.choice.id][this.factorId];
        } else {
            return null;
        }
    }

    get commentTotal(): number {
        if (this.value) {
            return this.value.comments;
        } else {
            return 0;
        }
    }

    get hasComments(): boolean {
        return this.commentTotal > 0;
    }

    async mounted(): Promise<void> {
        this.loadComments(this.hasComments);
    }

    /* To Do: Unmounted */

    @Watch("hasComments")
    async loadComments(val: boolean): Promise<void> {
        if (val) {
            this.$emit("loading-start");
            const comments = await commentModule.fetchComments({
                choice_id: this.choice.id,
                factor_id: this.factor.id,
            });
            await Promise.all([
                comments
                    .filter((comment) => comment.num_replies > 0)
                    .map(async (comment) => {
                        await commentModule.fetchReplies(comment.id);
                    }),
            ]);
            this.$emit("fetched-comments", comments);
            this.$emit("loading-end");
        }
    }
}
