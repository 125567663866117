
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {},
})
export default class CommentCellBtn extends Vue {
    @Prop({ default: 0, type: Number })
    total!: number;

    @Prop({ default: true, type: Boolean })
    showTotal!: boolean;
}
