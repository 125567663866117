
import { Vue, Component } from "vue-property-decorator";
import { Rules } from "@/helpers/FormRules";
import { Auth } from "aws-amplify";
import PasswordInput from "@/components/ui/PasswordInput.vue";
@Component({
    components: {
        PasswordInput,
    },
})
export default class Signup extends Vue {
    private isFormValid = false;
    private email = "";
    private first = "";
    private last = "";

    private password = "";
    private confirmPassword = "";

    private signupLoading = false;
    private errorMessage = "";

    private rules = Rules;

    private updatePassword(value: string): void {
        this.password = value;
    }

    private updateConfirmPassword(value: string): void {
        this.confirmPassword = value;
    }

    private async signup(): Promise<void> {
        this.signupLoading = true;
        this.$emit("loading-start");
        try {
            await Auth.signUp({
                username: this.email,
                password: this.password,
                attributes: {
                    given_name: this.first.length ? this.first : this.email, // optional
                    family_name: this.last.length ? this.last : " ", // optional - E.164 number convention
                    // other custom attributes
                },
                autoSignIn: {
                    // optional - enables auto sign in after user is confirmed
                    enabled: true,
                },
            });
            this.$emit("loading-end");
            this.$emit("email-redirect", {
                email: this.email,
                hash: "verify",
            });
        } catch (error: any) {
            this.$emit("loading-end");
            this.setErrorMsg(error.message);
        }
        this.signupLoading = false;
    }

    private setErrorMsg(error: any): void {
        this.errorMessage = error;
        setTimeout(() => {
            this.errorMessage = "";
        }, 5000);
    }
}
