import { render, staticRenderFns } from "./AccountView.vue?vue&type=template&id=c4043798&scoped=true&"
import script from "./AccountView.vue?vue&type=script&lang=ts&"
export * from "./AccountView.vue?vue&type=script&lang=ts&"
import style0 from "./AccountView.vue?vue&type=style&index=0&id=c4043798&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4043798",
  null
  
)

export default component.exports