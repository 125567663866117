
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Factor, Choice, Tab, UserPermission } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import Workspaces from "@/store/modules/Workspaces";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import CommentBase from "@/components/mixins/CommentBase";
import CommentChoices from "@/components/comments/tab/CommentChoices.vue";
import CommentSettings from "@/components/comments/tab/CommentSettings.vue";
import ComparePagination from "@/components/compare/ui/ComparePagination.vue";
import CompareColumnHeaders from "@/components/compare/ui/CompareColumnHeaders.vue";
import CommentUserRecursive from "@/components/comments/userTab/CommentUserRecursive.vue";
import AccessDenied from "@/components/users/AccessDenied.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";
import { saveTab } from "@/helpers/TabHelper";

const decisionsModule = getModule(Decisions);
const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        ViewWrapper,
        CommentChoices,
        CommentSettings,
        ComparePagination,
        CompareColumnHeaders,
        CommentUserRecursive,
        AccessDenied,
        ViewAppCreator,
    },
})
export default class CommentsUsersView extends mixins(CommentBase) {
    private darkBorder = true;
    private usersLoading = false;
    private allUsers: UserPermission[] = [];

    private appCreateOpen = false;

    get pageTitle(): string {
        if (this.isMainTool || !(this.tabSettings && this.tabSettings.title)) {
            return "Comments | Users";
        } else {
            return this.tabSettings.title;
        }
    }

    get workspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get choiceLabelPlural(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choicesLabel(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }

    /* Compare Values can be sorted here */
    get compareChoices(): Choice[] {
        return this.choices;
    }

    get compareUsers(): UserPermission[] {
        return this.allUsers;
    }

    get columnPages(): number {
        if (!this.pagination.columns.all && this.pagination.columns.items > 0) {
            return this.compareChoices.length - this.pagination.columns.items;
        } else {
            return 0;
        }
    }

    get paginationUsers(): UserPermission[] {
        if (!this.pagination.columns.all) {
            return this.compareUsers.slice(
                this.pagination.columns.position,
                this.pagination.columns.position + this.pagination.columns.items
            );
        } else {
            return this.compareUsers;
        }
    }

    get selectedChoiceId(): number | null {
        return this.selectedChoice ? this.selectedChoice.id : null;
    }

    /* Tab Settings */
    get currentUiOptions(): string {
        return JSON.stringify({
            mainColumn: this.mainColumnWidth,
            dataColumn: this.dataColumnWidth,
            paddingX: this.paddingX,
            paddingY: this.paddingY,
            borderX: this.borderX,
            borderY: this.borderY,
            pagination: this.pagination,
            fullSingleCol: this.fullSingleCol,
            showTotal: this.showTotal,
        });
    }

    get currentSettings(): Tab {
        if (this.tabSettings) {
            return {
                ...this.tabSettings,
                json: this.currentUiOptions,
                filter_choice_id: this.selectedChoice
                    ? this.selectedChoice.id
                    : undefined,
            };
        } else {
            return {
                id: -1,
                decision_id: this.decisionId ? this.decisionId : -1,
                title: "Comments",
                json: this.currentUiOptions,
                edit_flags: "",
                display_flags: "",
                filter_type: "choice",
                row_type: "factor",
                column_type: "viewpoint",
                filter_choice_id: this.selectedChoiceId
                    ? this.selectedChoiceId
                    : undefined,
                type: "CommentsUsersView",
            };
        }
    }

    private async saveSettings(): Promise<void> {
        if (this.tabSettings && this.isEdit) {
            this.loading = true;
            await saveTab({
                tab: {
                    ...this.currentSettings,
                },
                new: false,
                factors: [],
                choices: this.selectedChoices,
                viewpoints: [],
            });
            this.loading = false;
        } else {
            this.appCreateOpen = true;
        }
    }

    private formatUser(user: UserPermission): string {
        if (user.user.first) {
            if (user.user.last) {
                return `${user.user.first} ${user.user.last}`;
            } else {
                return user.user.first;
            }
        } else if (user.user.email) {
            return user.user.email;
        } else {
            return "User";
        }
    }

    async mounted(): Promise<void> {
        this.onWatchWorkspaceId(this.workspaceId);
        this.loadingTabSettings = true;
        await this.loadTabOptions();
        this.loadingTabSettings = false;
    }

    @Watch("selectedChoiceId")
    async onChoiceChange(newVal: number | null): Promise<void> {
        if (newVal) {
            await choiceModule.getChoiceValues({
                choice_id: newVal,
                root_only: true,
            });
        }
    }

    @Watch("workspaceId")
    async onWatchWorkspaceId(newVal: number | null): Promise<void> {
        if (newVal) {
            this.usersLoading = true;
            this.allUsers = await GRAPHQL_API.getWorkspaceUsers(newVal);
            this.usersLoading = false;
        }
    }
}
