
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import FlashNotifications from "@/store/modules/FlashNotifications";
import {
    Factor,
    FactorType,
    Unit,
    Value,
    ViewpointMapping,
    fileValue,
} from "@/graphql/API";
import { updateFactorL } from "@/helpers/FactorHelper";
import FactorCreator from "@/components/model/FactorCreator.vue";
import Choices from "@/store/modules/Choices";
import DateTimePicker from "@/components/values/DateTimePicker.vue";
import CategoryValueDisplay from "@/components/values/CategoryValueDisplay.vue";
import Domains from "@/store/modules/Domains";
import Decisions from "@/store/modules/Decisions";
import Workspaces from "@/store/modules/Workspaces";

import RichTextEditor from "@/components/ui/RichTextEditor.vue";
import {
    createUpdateChoiceValue,
    removeChoiceValue,
} from "@/helpers/ChoiceHelper";
import { FactorOptions } from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import FileUpload from "@/components/common/media/FileUpload.vue";
import MediaValueDisplay from "@/components/choices/MediaValueDisplay.vue";

const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);
const domainModule = getModule(Domains);
const flashModule = getModule(FlashNotifications);
const decisionsModule = getModule(Decisions);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        FactorCreator,
        DateTimePicker,
        CategoryValueDisplay,
        RichTextEditor,
        FileUpload,
        MediaValueDisplay,
    },
})
export default class FactorValueDisplay extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ required: true })
    factor!: Factor;

    @Prop({ default: () => ({}), type: Object })
    value!: Value;

    @Prop({ default: true, type: Boolean })
    showName!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: -1, type: Number })
    viewpointId!: number;

    @Prop()
    choiceId!: number;

    @Prop({ default: "", type: String })
    rowId!: string;

    @Prop()
    viewpointMapping!: ViewpointMapping;

    @Prop({ default: false, type: Boolean })
    isPartOfTable!: boolean;

    @Prop({ default: false, type: Boolean })
    isThumbnailDisplay!: boolean;

    @Prop({ default: false, type: Boolean })
    autoSaveMedia!: boolean;

    @Prop({ default: true, type: Boolean })
    clearBtn!: boolean;

    @Prop({ default: false, type: Boolean })
    hideDesc!: boolean;

    @Prop({ default: true, type: Boolean })
    condensed!: boolean;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private open = false;
    private internalValue = "";
    private internalDescription = "";
    private internalEnumVal: number | null = null;
    private loading = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private internalJson: any = "";
    private isFormValid = false;
    private deleteLoading = false;

    private rules = {
        isMinValid: (
            val: string,
            min: number | undefined,
            max: number | undefined
        ) => {
            const numVal = Number(val);
            if (min != undefined && numVal) {
                return numVal >= min
                    ? true
                    : `Value is out of range (${
                          min != null ? min : "Unbounded"
                      }, ${max ? max : "Unbounded"})`;
            } else {
                return true;
            }
        },
        isMaxValid: (
            val: string,
            min: number | undefined,
            max: number | undefined
        ) => {
            const numVal = Number(val);
            if (max != undefined && numVal) {
                return numVal <= max
                    ? true
                    : `Value is out of range (${
                          min != null ? min : "Unbounded"
                      }, ${max ? max : "Unbounded"})`;
            } else {
                return true;
            }
        },
        isStringMinValid: (
            val: string,
            min: number | undefined,
            max: number | undefined
        ) => {
            if (min != undefined && val) {
                return val.length >= min
                    ? true
                    : `Length must be greater than ${min} characters.`;
            } else {
                return true;
            }
        },
        isStringMaxValid: (
            val: string,
            min: number | undefined,
            max: number | undefined
        ) => {
            if (max != undefined && val) {
                return val.length <= max
                    ? true
                    : `Length must be less than ${max} characters`;
            } else {
                return true;
            }
        },
    };

    get types(): { text: string; id: string }[] {
        return modelModule.valueTypes;
    }

    get selectedChoiceId(): number | null {
        return choiceModule.selectedChoiceId;
    }

    get valueMap(): { [id: number]: Value } {
        return choiceModule.values;
    }

    get factorTypes(): { [id: number]: FactorType } {
        return modelModule.factorTypes;
    }

    get valueType(): string {
        return this.factor.value_type;
    }

    get units(): { [id: number]: Unit } {
        return getModule(Domains).units;
    }

    get currentWorkspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get currentDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get fileKey(): string {
        return `${this.currentWorkspaceId ? this.currentWorkspaceId : ""}/${
            this.currentDecisionId ? this.currentDecisionId : ""
        }/${this.factor ? this.factor.id : "/assets"}`;
    }

    get viewpointUnit(): Unit | null {
        if (this.viewpointMapping == null) {
            return null;
        }

        if (
            this.viewpointMapping != null &&
            this.viewpointMapping.unit_id != null
        ) {
            return this.units[this.viewpointMapping.unit_id];
        }

        return null;
    }

    get unitMultiplier(): string {
        if (
            this.viewpointUnit &&
            domainModule.unitConvs[
                `${this.factor.unit_id}-${this.viewpointUnit.id}`
            ]
        ) {
            console.log(
                parseFloat(
                    domainModule.unitConvs[
                        `${this.factor.unit_id}-${this.viewpointUnit.id}`
                    ].multiplier
                )
            );
            return domainModule.unitConvs[
                `${this.factor.unit_id}-${this.viewpointUnit.id}`
            ].multiplier;
        }

        return "1";
    }

    get unitAbrv(): string {
        if (this.factor.unit_id) {
            const unit = this.units[this.factor.unit_id];
            if (unit) {
                return unit.abrv;
            }
        }
        return "";
    }

    get factorDescription(): string | undefined {
        return this.factor.description;
    }

    get factorMin(): number | undefined {
        return this.factor.min;
    }

    get factorMax(): number | undefined {
        return this.factor.max;
    }

    mounted(): void {
        this.onChangeValue();
        this.onChangeDescription();
    }

    private async createValue() {
        if (
            this.choiceId &&
            this.factor.id != null &&
            this.internalValue != null &&
            this.internalValue != this.value.value &&
            this.isFormValid
        ) {
            console.log("Internal Value Exists and is different");
            this.loading = true;
            const value = "" + this.internalValue;
            try {
                await createUpdateChoiceValue({
                    choice_id: this.choiceId,
                    factor_id: this.factor.id,
                    row_id: this.rowId,
                    value: value,
                    json: this.internalJson ? this.internalJson : "",
                });
            } catch (e) {
                const err = e as GraphQLResult<any>;
                const message = err?.errors
                    ? err.errors[0].message
                    : "Something went wrong.";
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                flashModule.error({
                    message: message,
                    duration: 3000,
                });
            }
        }
        this.loading = false;
    }

    private getFactorStyleClass(factor: Factor): string {
        if (factor.json && !factor.json.startsWith("v")) {
            return JSON.parse(factor.json).style_class
                ? JSON.parse(factor.json).style_class
                : "vp-body-1";
        }
        return "vp-body-1";
    }

    @Watch("value")
    private onChangeValue() {
        this.internalValue = this.value.value;
        this.internalEnumVal = this.value.enum_value_id;
    }

    @Watch("factorDescription")
    private onChangeDescription() {
        if (this.factorDescription)
            this.internalDescription = this.factorDescription;
    }

    private async deleteValue() {
        if (this.choiceId && this.factor.id != null) {
            this.deleteLoading = true;
            await removeChoiceValue({
                choice_id: this.choiceId,
                factor_id: this.factor.id,
                row_id: this.rowId,
                value_type: this.factor.value_type,
                current_value: this.value.value,
            });
            this.deleteLoading = false;
        }
    }

    private async saveDate(dateValue: string) {
        this.internalValue = dateValue;
        this.createValue();
    }

    private async saveFiles(files: fileValue[]): Promise<void> {
        this.internalValue = JSON.stringify(files);
        this.createValue();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async uploadFile(fileData: any) {
        this.internalValue = fileData.fileName;
        this.internalJson = JSON.stringify(fileData);
        this.createValue();
    }

    private async saveText(textValue: string) {
        this.internalValue = textValue;
        this.createValue();
    }

    private updateTextDescription(descriptionValue: string) {
        this.internalDescription = descriptionValue;
        this.updateDescription();
    }

    private async updateCategory(value: number) {
        if (this.choiceId && this.factor.id != null) {
            this.loading = true;
            let result = await createUpdateChoiceValue({
                choice_id: this.choiceId,
                factor_id: this.factor.id,
                row_id: this.rowId,
                enum_value_id: value,
                value: value.toString(),
                json: this.internalJson ? this.internalJson : "",
            });
            this.loading = false;
        }
    }

    private convertStrToNum(stringVal: string): number {
        if (stringVal != null) return parseInt(stringVal);
        else {
            return 0;
        }
    }

    private calculateConversionValue(value: string, convRate: string): string {
        if (!value || !convRate) {
            return value;
        }

        //Get number of decimal places for value and conversion rate
        const numberDecimalPlacesValue = value.includes(".")
            ? value.length - value.lastIndexOf(".") - 1
            : 0;
        const numberDecimalPlacesConv = convRate.includes(".")
            ? convRate.length - convRate.lastIndexOf(".") - 1
            : 0;

        //Number of decimal places is equal to the larger of the number of decimal places for value and convRate
        const decimalPlaces =
            numberDecimalPlacesValue >= numberDecimalPlacesConv
                ? numberDecimalPlacesValue
                : numberDecimalPlacesConv;
        const result = parseFloat(value) * parseFloat(convRate);
        //If result is a whole number drop all decimal places
        if (result % 1 == 0) {
            return result.toFixed(0);
        }
        return result.toFixed(decimalPlaces);
    }

    private updateDescription() {
        if (
            this.internalDescription != null &&
            this.internalDescription != this.factor.description
        ) {
            const defaultOptions = {
                style_class: "vp-body-1",
                categories: [],
            };
            console.log(this.parentId);
            this.factor.description = this.internalDescription;
            updateFactorL({
                factor: this.factor,
                parentId: this.parentId,
                valueType: this.factor.value_type,
                options: this.factor.json ?? JSON.stringify(defaultOptions),
                format: this.factor.format,
                m_score: this.factor.m_score,
                m_index: this.factor.m_index,
                weight: this.factor.weight,
                score_rule_id: this.factor.score_rule_id,
                index_rule_id: this.factor.index_rule_id,
                set_id: this.factor.set_id,
            });
        }
    }

    @Watch("isFormValid")
    private onWatchIsFormValid() {
        if (this.isFormValid) {
            this.$emit("dec-err");
        } else {
            this.$emit("inc-err");
        }
    }
}
