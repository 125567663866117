export const Urls = {
    HOME: "/",
    DECISION: "/decision",
    MODEL: "/model",
    MODEL_ADMIN: "/model-admin",
    MODEL_SEARCH: "/model/search",
    MODEL_IMPORT_FRAMEWORK: "/model/import",
    MODEL_TABLE: "/model/table-names",
    MODEL_AI: "/model/ai",
    MODEL_EXTRACT: "/model/extract",
    MODEL_LABELS: "/model/labels",
    CHOICES: "/choices",
    CHOICE: "/choices/editor",
    CHOICES_SEARCH: "/choices/search",
    CHOICES_AI: "/choices/ai",
    CHOICES_LOAD: "/choices/load",
    CHOICES_EXTRACT: "/choices/extract",
    SCORING: "/scoring",
    SCORINGCLASS: "/scoring-class",
    SCORINGMANAGER: "/scoring-manager",
    CLASSCLUSTER: "/class-list",
    SCORERULE: "/score-rule",
    VIEWPOINTS: "/viewpoints",
    VIEWPOINTSCORE: "/viewpoint-score",
    DOMAINS: "/domains",
    UNITS: "/units",
    CHOICESCORER: "/choice-score",
    WEIGHTSEDITOR: "/weights",
    COMPARE: "/compare",
    APPMANAGER: "/app-list",
    RANK: "/rank",
    APPS: "/app",
    WORKSPACES: "/workspaces",
    DECISIONS: "/frameworks",
    APPSLISTEDITOR: "/apps",
    TABEDITOR: "/apps-edit",
    APPSLIST: "/apps-list",
    USERS: "/users",
    ACCOUNT: "/account",
    LOGIN: "/login",
    SIGNUP: "/login/signup",
    VERIFY: "/login/signup/verify",
    RESET: "/login/reset",
    INVITATION: "/ws",
    COMMENTS: "/comments",
    COMMENTSUSERS: "/comments/users",
};

export const loginRoutes = {
    login: {
        component: "Login",
        back: "",
    },
    signup: {
        component: "Signup",
        back: "login",
    },
    reset: {
        component: "Reset",
        back: "login",
    },
    resetform: {
        component: "ResetForm",
        back: "reset",
    },
    verify: {
        component: "Verify",
        back: "signup",
    },
};
