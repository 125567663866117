
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Comments from "@/store/modules/Comments";

const commentModule = getModule(Comments);

@Component({
    components: {},
})
export default class CommentOrder extends Vue {
    private showOrderMenu = false;

    get order(): "desc" | "asc" {
        return commentModule.order;
    }

    private changeOrder(val: "asc" | "desc"): void {
        commentModule.changeOrder(val);
    }
}
