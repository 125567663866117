
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Factor, Choice, UserPermission, Value, Comment } from "@/graphql/API";
import CompareRow from "@/components/compare/ui/CompareRow.vue";
import CompareColumnMain from "@/components/compare/ui/CompareColumnMain.vue";
import CompareColumn from "@/components/compare/ui/CompareColumn.vue";
import CommentUserFactorChoice from "./CommentUserFactorChoice.vue";
import CommentUserCell from "./CommentUserCell.vue";
import { getModule } from "vuex-module-decorators";
import Choices from "@/store/modules/Choices";
import Comments from "@/store/modules/Comments";

const choiceModule = getModule(Choices);
const commentModule = getModule(Comments);

@Component({
    components: {
        CompareRow,
        CompareColumnMain,
        CompareColumn,
        CommentUserFactorChoice,
        CommentUserCell,
    },
})
export default class CommentUserRow extends Vue {
    @Prop({ required: true })
    factor!: Factor;

    @Prop()
    choice!: Choice;

    @Prop({ default: () => [], type: Array })
    users!: UserPermission[];

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({ type: Number, default: 0 })
    rowIndex!: number;

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({ default: false, type: Boolean })
    highlight!: boolean;

    @Prop({ default: false, type: Boolean })
    group!: boolean;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    @Prop({ default: false, type: Boolean })
    expand!: boolean;

    @Prop({ default: false, type: Boolean })
    last!: boolean;

    private loading = false;

    get customClass(): string {
        return this.expand
            ? ` vp-comment-row-highlight vp-comment-row-highlight-${this.depth}`
            : " ";
    }

    get choiceId(): string {
        return `${this.choice.id}`;
    }

    get factorId(): string {
        return `${this.factor.id}`;
    }

    get value(): Value | null {
        if (
            choiceModule.choiceValues[this.choice.id] &&
            choiceModule.choiceValues[this.choice.id][this.factorId]
        ) {
            return choiceModule.choiceValues[this.choice.id][this.factorId];
        } else {
            return null;
        }
    }

    get index(): string | null {
        if (this.choice && this.factor) {
            return `${this.choice.id}-${this.factor.id}`;
        } else {
            return null;
        }
    }

    get comments(): Comment[] {
        if (this.index) {
            return commentModule.keyedList[this.index]
                ? commentModule.keyedList[this.index]
                : [];
        } else {
            return [];
        }
    }

    get replyParentIds(): number[] {
        return this.comments
            .filter((comment) => comment.num_replies > 0)
            .map((comment) => comment.id);
    }

    get replies(): Comment[] {
        return this.replyParentIds
            .map((id) => commentModule.keyedReplies[id])
            .reduce((acc, item) => {
                return [...acc, ...item];
            }, []);
    }

    get payload(): {
        factor_id: number;
        choice_id: number;
    } | null {
        if (this.factor && this.choice) {
            return {
                factor_id: this.factor.id,
                choice_id: this.choice.id,
            };
        } else {
            return null;
        }
    }

    private subscribePayload(payload: {
        factor_id: number;
        choice_id: number;
    }): void {
        commentModule.activateCell(payload);
    }

    private unSubscribePayload(payload: {
        factor_id: number;
        choice_id: number;
    }): void {
        commentModule.removeCell(payload);
    }

    mounted(): void {
        this.onPayloadChange(this.payload, null);
    }

    @Watch("payload", { immediate: true, deep: true })
    onPayloadChange(
        newVal: { factor_id: number; choice_id: number } | null,
        oldVal: { factor_id: number; choice_id: number } | null,
    ): void {
        if (newVal) {
            this.subscribePayload(newVal);
        }
        if (oldVal) {
            this.unSubscribePayload(oldVal);
        }
    }
}
