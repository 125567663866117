
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Comments from "@/store/modules/Comments";
import { Choice, Factor, Comment, CommentPayload } from "@/graphql/API";
import CommentEditor from "@/components/comments/tab/panel/CommentEditor.vue";
import CommentTree from "@/components/comments/tab/panel/CommentTree.vue";
import CommentPanelHeader from "@/components/comments/tab/panel/CommentPanelHeader.vue";

const commentModule = getModule(Comments);

@Component({
    components: {
        CommentEditor,
        CommentTree,
        CommentPanelHeader,
    },
})
export default class CommentSideBar extends Vue {
    @Prop({ required: true })
    factor!: Factor;

    @Prop({ required: true })
    choice!: Choice;

    @Prop({ default: true, type: Boolean })
    editorOpen!: boolean;

    @Prop({ default: true, type: Boolean })
    compOpen!: boolean;

    @Prop({ default: false, type: Boolean })
    topEditorCancel!: boolean;

    private initialLoading = false;
    private loading = false;
    private refresh = "";
    private showOrderMenu = false;
    // private order = "desc";

    private replyActive: number | null = null;
    private showError = false;

    get order(): string {
        return commentModule.order;
    }

    get payload(): CommentPayload {
        return {
            factor_id: this.factor.id,
            choice_id: this.choice.id,
        };
    }

    get index(): string {
        return `${this.choice.id}-${this.factor.id}`;
    }

    get comments(): Comment[] {
        return commentModule.keyedList[this.index]
            ? commentModule.keyedList[this.index]
            : [];
    }

    get currCell(): any {
        return commentModule.currentCell;
    }

    private setActiveReply(val: number | null): void {
        this.replyActive = val;
    }

    private setOrder(val: "asc" | "desc"): void {
        // this.order = val;
        /* Set Vuex Value */
        commentModule.changeOrder(val);
    }

    private refreshSideBar(): void {
        const currentDate = new Date();
        this.refresh = currentDate.toString();
    }

    private async saveComment(val: string): Promise<void> {
        this.loading = true;
        if (this.choice && this.factor) {
            await commentModule.createComment({
                choice_id: this.choice.id,
                factor_id: this.factor.id,
                comment: val,
            });
        }
        /* Clear */
        this.refreshSideBar();
        this.loading = false;
    }

    @Watch("payload", { immediate: true, deep: true })
    async onPayloadChange(
        newVal: CommentPayload,
        oldVal: CommentPayload
    ): Promise<void> {
        this.initialLoading = true;
        this.refreshSideBar();

        if (newVal.factor_id && newVal.choice_id) {
            try {
                await Promise.all([
                    commentModule.fetchComments(newVal),
                    commentModule.activateCell(newVal),
                ]);
            } catch {
                this.showError = true;
            }
        }
        if (oldVal && oldVal.choice_id && oldVal.factor_id) {
            commentModule.removeCell(oldVal);
        }
        this.initialLoading = false;
    }
}
